@font-face {
  font-family: "RobotoCondensed";
  src: url("./asset/fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "RobotoCondensed";
  src: url("./asset/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

* {
  font-family: RobotoCondensed, Arial;
  color: #333;
}
